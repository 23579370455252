import React from 'react'
import Fade from 'react-reveal/Fade'

const ContentBlocksIntroduction = ({
  title,
  text,
  marginLarge,
  mobileAlignmentLeft,
}) => {
  return (
    <Fade delay={225} bottom distance={'25px'} duration={2250}>
      <div
        className={`gutters w-10/12 lg:w-1/2 mx-auto ${
          mobileAlignmentLeft ? 'md:text-center' : 'text-center'
        }`}
      >
        {title && (
          <h3
            className={`${
              marginLarge ? 'mb-8 md:mb-12 lg:mb-18' : 'mb-6 lg:mb-8'
            } uppercase tracking-wider text-xs`}
          >
            {title}
          </h3>
        )}
        {text && (
          <div
            className="rte font-light text-lg sm:text-2xl font-light"
            dangerouslySetInnerHTML={{ __html: text.html }}
          ></div>
        )}
      </div>
    </Fade>
  )
}

export default ContentBlocksIntroduction
