import React from 'react'
import lottie from 'lottie-web'
import Fade from 'react-reveal/Fade'

class Whakapapa extends React.Component {
  constructor(props) {
    super(props)
    this.container = React.createRef()
    this.parentContainer = React.createRef()
  }

  state = {
    animation: Object,
  }

  animation = () => {
    const data = {
      wrapper: this.refs.container,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '/lottie-whakapapa.json',
    }
    this.setState({
      animation: lottie.loadAnimation(data),
    })
  }

  runAnimation = () => {
    let container = this.refs.parentContainer
    let animObject = this.state.animation
    let buffer = window.innerHeight / 2
    let containerHeight = container.offsetHeight
    let containerTop = container.offsetTop
    let duration = containerHeight - window.innerHeight / 2
    let scrollPosition = window.scrollY - (containerTop - buffer)
    let maxFrames = animObject.totalFrames
    let frame = (maxFrames / 100) * (scrollPosition / (duration / 100))

    if (window.scrollY >= containerTop - buffer) {
      if (frame <= maxFrames) {
        animObject.goToAndStop(frame, true)
      }
    } else {
      animObject.goToAndStop(0, true)
    }
  }

  eventScroll = () => {
    this.runAnimation()
  }

  componentDidMount() {
    this.animation()
    window.addEventListener('scroll', this.eventScroll, false)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.eventScroll, false)
  }

  render() {
    return (
      <div className="gutters overflow-hidden">
        <div className="lg:mx-1/24">
          <Fade delay={225} bottom distance={'25px'} duration={2250}>
            <div className="flex flex-wrap mb-36 lg:mb-24">
              <div className="w-full sm:w-1/2">
                <h2 className="text-3xl md:text-6xl lg:text-7xl font-thin mb-12 lg:mb-24">
                  Whakapapa
                </h2>
              </div>
              <div className="w-full sm:w-1/2 sm:pt-4">
                <div className="md:max-w-sm lg:max-w-lg font-light md:text-lg lg:text-xl xl:text-2xl rte">
                  <p>
                    Greg’s whakapapa traces back to Rākaihautū, Waitaha, and
                    Ngāti Māmoe tribes. His Ngāi Tahu connection is also one of
                    great importance to him and his whānau, and is evident in
                    the potting techniques employed by Greg to this day.
                  </p>
                  <p>
                    Through the knowledge and tradition of his ancestors and
                    those that have come before him, Greg is proud to continue
                    the discipline of potting. This allows for the ethical
                    harvest of fish from our waters while delivering a product
                    of the highest quality.
                  </p>
                </div>
              </div>
            </div>
          </Fade>
          <div className="sm:hidden mb-24">
            <Fade delay={225} bottom distance={'25px'} duration={2250}>
              <div className="font-light rte">
                <p className="mb-6">
                  Whakapapa translates from the Māori language as “to whom I
                  belong and where I belong”.
                </p>
                <p className="mb-6">
                  <span className="font-medium">Rākaihautū</span> arrived in
                  what is thought to be the first Māori canoe to land in New
                  Zealand, and founded the Waitaha tribe.{' '}
                  <span className="font-medium">Hotumamoe</span> founded the
                  Ngāti Māmoe tribe. <span className="font-medium">Paikea</span>{' '}
                  is said to have arrived on a whale.
                </p>
                <p>
                  All three tribal strands combine to make up Ngai Tahu – the
                  principal tribe in the South Island of New Zealand
                </p>
              </div>
            </Fade>
          </div>
          <div ref="parentContainer" className="relative">
            <div className="hidden sm:block absolute top-0 right-0 w-1/2 mt-24 lg:mt-36">
              <Fade delay={225} bottom distance={'25px'} duration={2250}>
                <div className="md:max-w-sm lg:max-w-lg font-light md:text-lg lg:text-xl xl:text-2xl rte">
                  <p className="mb-6">
                    Whakapapa translates from the Māori language as “to whom I
                    belong and where I belong”.
                  </p>
                  <p className="mb-6">
                    <span className="font-medium">Rākaihautū</span> arrived in
                    what is thought to be the first Māori canoe to land in New
                    Zealand, and founded the Waitaha tribe.{' '}
                    <span className="font-medium">Hotumamoe</span> founded the
                    Ngāti Māmoe tribe.{' '}
                    <span className="font-medium">Paikea</span> is said to have
                    arrived on a whale.
                  </p>
                  <p>
                    All three tribal strands combine to make up Ngai Tahu – the
                    principal tribe in the South Island of New Zealand
                  </p>
                </div>
              </Fade>
            </div>
            <div className="relative z-10" ref="container"></div>
          </div>
        </div>
      </div>
    )
  }
}

export default Whakapapa
