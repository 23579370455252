import React from 'react'

const ScrollIndicator = () => {
  return (
    <div className="scroll-indicator hidden md:block">
      <div className="scroll-indicator__el">
        <div className="scroll-indicator__el-inner"></div>
      </div>
    </div>
  )
}

export default ScrollIndicator
