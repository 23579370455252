import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Hero from '../components/Hero'
import ScrollIndicator from '../components/Ui/ScrollIndicator'
import Footer from '../components/Footer'
import ContentBlocksIntroduction from '../components/ContentBlocks/ContentBlocksIntroduction'
import ContentBlocksLead from '../components/ContentBlocks/ContentBlocksLead'
import Whakapapa from '../components/Whakapapa'
import TheGreatMigration from '../components/TheGreatMigration'

const footerLinks = ['present', 'future']

const PastPage = ({ location, data }) => {
  const page = data.prismicPast.data

  const sources = [
    data.mobileHeroImage.childImageSharp.fluid,
    {
      ...data.heroImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <Layout>
      <SEO
        title={page.meta_title}
        description={page.meta_description}
        location={location}
      />
      <ScrollIndicator />
      <Hero
        title="Past"
        preTitle="Okains Bay"
        image={sources}
        scrollLink
        caption="Donne, Leanne, Beth and Greg Summerton — 1967"
      />
      <div id="discover" className="py-48">
        <div className="mb-48">
          <ContentBlocksLead
            title={page.lead_title}
            text={page.lead_text}
            image={page.lead_image}
          />
        </div>
        <div className="mb-48">
          <ContentBlocksIntroduction text={page.lead_secondary_text} />
        </div>
        <div className="mb-48 md:gutters lg:mx-1/24">
          <TheGreatMigration />
        </div>
        <Whakapapa />
        <Fade delay={225} duration={2250}>
          <div className="mt-56 mb-44 flex items-center justify-center">
            {page.main_primary_text && (
              <div className="gutters w-10/12 lg:w-1/2 mx-auto text-center">
                <div
                  className="rte font-light text-lg md:text-2xl"
                  dangerouslySetInnerHTML={{
                    __html: page.main_primary_text.html,
                  }}
                ></div>
              </div>
            )}
          </div>
        </Fade>
        <div className="sm:gutters mb-44">
          <div className="text-center">
            <Fade delay={225} duration={2250}>
              {page.main_primary_image && (
                <Img
                  fluid={
                    page.main_primary_image.localFile.childImageSharp.fluid
                  }
                  alt={page.main_primary_image.alt}
                />
              )}
              {page.main_primary_image.alt && (
                <p className="gutters text-sm font-light mt-4">
                  {page.main_primary_image.alt}
                </p>
              )}
            </Fade>
          </div>
        </div>
        <div className="sm:gutters">
          <div className="lg:ml-3/24 lg:mr-4/24">
            <div className="sm:flex lg:justify-between">
              <Fade delay={225} duration={2250}>
                <div className="sm:w-5/12 lg:w-9/17 mb-10 sm:mb-0">
                  <div className="sm:flush-left lg:flush-none">
                    {page.main_secondary_image && (
                      <Img
                        fluid={
                          page.main_secondary_image.localFile.childImageSharp
                            .fluid
                        }
                        alt={page.main_secondary_image.alt}
                      />
                    )}
                    {page.main_secondary_image.alt && (
                      <p className="text-sm font-light mt-4 gutters lg:gutters-none">
                        {page.main_secondary_image.alt}
                      </p>
                    )}
                  </div>
                </div>
              </Fade>
              <Fade delay={450} duration={2250}>
                <div className="gutters sm:gutters-none sm:w-5/12 lg:w-6/17 flex items-center sm:ml-1/12 lg:ml-0">
                  <div className="w-full">
                    {page.main_secondary_title && (
                      <h3 className="text-xs tracking-wider uppercase mb-8 lg:mb-10">
                        {page.main_secondary_title}
                      </h3>
                    )}
                    {page.main_secondary_text && (
                      <div
                        className="rte text-lg font-light"
                        dangerouslySetInnerHTML={{
                          __html: page.main_secondary_text.html,
                        }}
                      ></div>
                    )}
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
      <Footer links={footerLinks} />
    </Layout>
  )
}

PastPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export const pagePastQuery = graphql`
  query {
    heroImage: file(relativePath: { eq: "hero/past.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mobileHeroImage: file(relativePath: { eq: "hero/mobile/past.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1048) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    prismicPast {
      data {
        lead_image {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1536, maxHeight: 1124) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        lead_secondary_text {
          html
          text
        }
        lead_title
        lead_text {
          html
          text
        }
        main_primary_text {
          html
          text
        }
        main_primary_image {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 2700, maxHeight: 1440) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        main_secondary_image {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1058, maxHeight: 1536) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        meta_title
        meta_description
        main_secondary_title
        main_secondary_text {
          text
          html
        }
      }
    }
  }
`

export default PastPage
