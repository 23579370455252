import React from 'react'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'

const Lead = ({ title, text, image }) => {
  return (
    <div className="lg:gutters">
      <div className="lg:flex">
        <Fade delay={450} duration={2250}>
          <div className="gutters lg:px-0 lg:w-11/24 flex items-center justify-center">
            <div className="mx-1/12 md:mr-2/12 lg:mx-1/11 my-18 lg:my-0 text-center md:text-left">
              {title && (
                <h3 className="text-xs tracking-wider uppercase mb-8 lg:mb-10">
                  {title}
                </h3>
              )}
              <div
                className="rte text-xl md:text-2xl font-light"
                dangerouslySetInnerHTML={{ __html: text.html }}
              ></div>
            </div>
          </div>
        </Fade>
        <Fade delay={225} duration={2250}>
          <div className="lg:w-13/24">
            <Img
              fluid={image.localFile.childImageSharp.fluid}
              alt={image.alt}
            />
            {image.alt && (
              <p className="text-sm font-light mt-4 gutters lg:gutters-none">
                {image.alt}
              </p>
            )}
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default Lead
