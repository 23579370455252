import React, { useRef } from 'react'
import { BrowserView, MobileView } from 'react-device-detect'
import Fade from 'react-reveal/Fade'

const TheGreatMigration = () => {
  const videoRef = useRef(null)

  const playVideo = () => {
    videoRef.current.play()
  }

  return (
    <>
      <BrowserView>
        <Fade delay={225} duration={2250} onReveal={() => playVideo()}>
          <div>
            <h3 className="text-center text-xs tracking-wider uppercase mb-8">
              The Great Migration
            </h3>
            <video
              ref={videoRef}
              src="/Migration V3.mp4"
              type="video/mp4"
              muted
              loop
              controls
            ></video>
          </div>
        </Fade>
      </BrowserView>
      <MobileView>
        <Fade delay={225} duration={2250}>
          <div
            dangerouslySetInnerHTML={{
              __html: `
            <video
              muted
              autoplay
              playsinline
              loop
              controls
              src="/Migration V3.mp4"
            />`,
            }}
          />
        </Fade>
      </MobileView>
    </>
  )
}

export default TheGreatMigration
